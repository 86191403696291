import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import App from "./containers/App";
import ScrollToTop from "./Components/ScrollToTop";

import Bios from "./containers/Bios";
import Contact from "./containers/Contact";
import MissionStatement from "./containers/MissionStatement";
import Process from "./containers/Process";
import EllenStein from "./containers/Bios/EllenStein";
import AnnSullivan from "./containers/Bios/AnnSullivan";

export default () => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Switch>
          <Route path="/" exact={true} component={App} />
          <Route
            path="/missionstatement"
            exact={true}
            component={MissionStatement}
          />
          <Route path="/bios" exact={true} component={Bios} />
          <Route
            path="/bios/annsullivan"
            exact={true}
            component={AnnSullivan}
          />
          <Route path="/bios/ellenstein" exact={true} component={EllenStein} />
          <Route path="/contact" exact={true} component={Contact} />
          <Route path="/focusareas" exact={true} component={Process} />
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  );
};
