import React from 'react'
import { withFormik, Form, Field } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import ReCAPTCHA from 'react-google-recaptcha'
// Components
import Button from 'react-bootstrap/Button'
import Fade from 'react-bootstrap/Fade'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

const makeClasses = (touched, errors, fieldName, baseClass) => {
  let fieldClasses = ''
  if (baseClass) {
    fieldClasses = baseClass
  }
  if (touched[fieldName]) {
    const extraClass = errors[fieldName] ? 'is-invalid' : 'is-valid'
    fieldClasses += ' ' + extraClass
  }
  return fieldClasses
}

const ContactForm = ({
  errors,
  touched,
  isSubmitting,
  status,
  setFieldValue
}) => {

  const nameClasses = makeClasses(touched, errors, 'name', 'form-control')
  const emailClasses = makeClasses(touched, errors, 'email', 'form-control')
  const messageClasses = makeClasses(touched, errors, 'message', 'form-control')

  return (
  <Form>
    <Row>
      <Col md={6}>
        <div className="form-group">
          <label className="form-label">Your Name { touched.name && errors.name && <strong>&nbsp;<span className="text-danger">{errors.name}</span></strong>}</label>
          <Field className={nameClasses} type="text" name="name" placeholder="Your name"/>
        </div>
      </Col>
      <Col md={6}>
        <div className="form-group">
          <label className="form-label">Your Email { touched.email && errors.email && <strong>&nbsp;<span className="text-danger">{errors.email}</span></strong>}</label>
          <Field className={emailClasses} type="email" name="email" placeholder="Your email"/>
          <small className="text-muted form-text">We'll never share your email address</small>
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <div className="form-group">
          <label className="form-label">Message { touched.message && errors.message && <strong>&nbsp;<span className="text-danger">{errors.message}</span></strong>}</label>
          <Field className={messageClasses} component="textarea" rows="3" name="message" placeholder="Type your message here"/>
        </div>
      </Col>
    </Row>
    <hr className="mt-4 mb-4"/>
    
      <Fade
        in={status.success}
        appear={true}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <div className="alert alert-success fade">Your message was sent successfully!</div>
      </Fade>

      <Row>
        <Col>
          <div className="form-group">
          { touched.recaptcha && errors.recaptcha && <strong>&nbsp;<span className="text-danger">{errors.recaptcha}</span></strong>}
            <ReCAPTCHA
              name="recaptcha"
              sitekey="6Lcn3qIUAAAAABrXa9AG5iMf06xRz6OfHhaLeQN2"
              onChange={(response) => { setFieldValue("recaptcha", response)}}
            />
          </div>
        </Col>
      </Row>
          <Button className="btn btn-success" disabled={isSubmitting} type="submit" variant="success">Send us your message</Button>
      
    
  </Form>
)}

const FormikForm = withFormik({
  mapPropsToValues() {
    return {
      name: '', // Initial values here
      email: '',
      message: '',
      recaptcha: ''
    }
  },
  mapPropsToStatus() {
    return {
      success: false
    }
  },
  validationSchema: Yup.object().shape({
    email: Yup.string().email('Please enter a valid email').required('Please enter your email address'),
    name: Yup.string().required('Please enter your name'),
    message: Yup.string().max(500, 'Your message cannot be more than 255 characters').min(10,'Please make your message at least 10 characters long').required('Please enter your message'),
    recaptcha: Yup.string().required('Please prove that you are human')
  }),
  handleSubmit(values, { resetForm, setErrors, setSubmitting, setStatus}) {
    window.grecaptcha.reset()
    if (values.recaptcha === "") {
      setErrors({ recaptcha: 'Please prove you are human'})
      setSubmitting(false)
    } else {
      // Send post request:
      axios.post('https://sullivanstein.com/contactform.php', {
        name: values.name,
        email: values.email,
        message: values.message
      })
      .then(res => {
        if (res.data.success === false) {
          if (res.data.errors.name) setErrors({ name: res.data.errors.name })
          if (res.data.errors.email) setErrors({email: res.data.errors.email})
          if (res.data.errors.message) setErrors({message: res.data.errors.message})
        } else {
          resetForm()
          setStatus({ success: true })
          setTimeout(() => {
            setStatus({ success: false })
          }, 5000)
        }
        setSubmitting(false)
      })
    }
  }
})(ContactForm)

export default FormikForm
