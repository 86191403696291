import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
// css
import "../css/home.css";
// React-Bootstrap
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
// Components
import Logo from "../Components/LogoSvg";

const Home = props => {
  const isTall = props.windowHeight >= 850;
  const isXTall = props.windowHeight >= 1000;
  let containerClass = `home ${props.windowSize}`;
  containerClass += isTall ? " tall" : "";
  containerClass += isXTall ? " xtall" : "";

  return (
    <>
      <Helmet>
        <title>Sullivan Stein - Leadership Development</title>
      </Helmet>
      <Container className={containerClass}>
        <Row style={{ marginBottom: "2em" }}>
          <Col>
            <Logo
              className="home logo"
              scale={props.windowSize}
              colorhint="#0C4BC5"
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <h1 className="home statement">
              Executive Coaching and Organizational Consulting for Experienced
              and Emerging Leaders
            </h1>
          </Col>
        </Row>
        <Row>
          <Col xs>
            <Nav className="home justify-content-center">
              <Nav.Item>
                <Nav.Link as={Link} to="/missionstatement">
                  Mission Statement
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/focusareas">
                  Focus Areas
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/bios">
                  Bios
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/Contact">
                  Contact
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = state => ({
  windowWidth: state.window.width,
  windowHeight: state.window.height,
  windowSize: state.window.size
});

export default connect(mapStateToProps)(Home);
