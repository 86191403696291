import React from 'react';
import { Link } from 'react-router-dom';

const SidePreview = (props) => (
    <div className="sidepreview mb-4 d-none d-md-block" style={{position:'relative'}}>
        {props.link ? (<h3 className="mb-2"><Link to={props.link}>{props.title}</Link></h3>) : (<h3 className="mb-2">{props.title}</h3>)}
        {props.children}
        <Link className="stretched-link" to={props.link}>> {props.moreText || 'More'}</Link>
    </div>
);
export default SidePreview;