import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
// React bootstrap:
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
// Components:
import SiteFooter from "../Components/SiteFooter";
import SiteNav from "../Components/SiteNav";
import SidePreview from "../Components/SidePreview";
// CSS:
import "../css/process.css";
// Assets:
import founders from "../assets/Founders.jpg";

const Process = props => (
  <>
    <Helmet>
      <title>Sullivan Stein - Focus Areas</title>
    </Helmet>
    <div className={`page process d-flex flex-column ${props.windowSize}`}>
      <SiteNav active="process" />
      <Container className="main">
        <Row>
          <Col
            lg={9}
            md={8}
            className="column-primary column-border-right pr-5"
          >
            <h1 className="text-center mb-5">Focus Areas</h1>

            <h2>Focus Areas</h2>
            <hr />
            <strong>
              <ul className="list-unstyled">
                <li className="lead">
                  <a href="#advancement">Advancement</a>
                </li>
                <li className="lead">
                  <a href="#coaching">
                    Executive coaching and consulting for School Leaders and new
                    Heads of School
                  </a>
                </li>
                <li className="lead">
                  <a href="#enrollment_management">Enrollment Management</a>
                </li>
                <li className="lead">
                  <a href="#leadership_teams">Leadership Teams</a>
                </li>
                <li className="lead">
                  <a href="#governance">Governance</a>
                </li>
                <li className="lead">
                  <a href="#strategic_planning">Strategic Planning</a>
                </li>
                <li className="lead">
                  <a href="#succession_planning">Succession Planning</a>
                </li>
                <li className="lead">
                  <a href="#gender">Leadership and Gender</a>
                </li>
              </ul>
            </strong>
            <hr />

            <a name="advancement" href="#coaching" className="invisible">
              Advancement
            </a>
            <h3>Advancement</h3>
            <p>
              We will review best practices and resources in the advancement
              area, including a focus on annual funds, capital and endowment
              campaigns and the structure of the advancement office.
            </p>

            <a name="coaching" href="#coaching" className="invisible">
              Coaching
            </a>
            <h3>
              Executive coaching and consulting for School Leaders and new Heads
              of School
            </h3>
            <p>
              Our goal is to develop new strengths in leaders so they can
              problem solve on their own. This is done through monthly or more
              frequent conversations focusing on the areas of concern initiated
              by the client and helping them develop greater leadership
              capacities.
            </p>

            <a
              name="enrollment_management"
              href="#enrollment_management"
              className="invisible"
            >
              Enrollment Management
            </a>
            <h3>Enrollment Management</h3>
            <p>
              Enrollment Management consulting has become increasingly important
              to Independent School Boards with higher tuitions and financial
              aid budgets, as well as competing options which include Charter
              Schools, Home-Schooling, For-Profit Schools, and Public School
              Programs for Universal PreK students.
            </p>

            <a
              name="leadership_teams"
              href="#leadership_teams"
              className="invisible"
            >
              Leadership Teams
            </a>
            <h3>Leadership Teams</h3>
            <p>
              Administrative teams, departmental teams, and grade level leaders
              need to work successfully together. We will review the dynamics of
              each team, analyse individual strengths and note how these can be
              cooperatively utilized on the Leadership Team. We conduct
              Leadership Team retreats at the outset or conclusion of a school
              year.
            </p>

            <a name="governance" href="#governance" className="invisible">
              Governance
            </a>
            <h3>Governance</h3>
            <p>
              We can assist Boards to recruit and orient new trustees; we have
              worked with Board Chairs on preparing productive agendas for Board
              meetings and sub-committees, and strengthening the relationship
              with the Head of School; we can facilitate Board retreats
              incorporating emerging data on emerging trends.
            </p>

            <a
              name="strategic_planning"
              href="#strategic_planning"
              className="invisible"
            >
              Strategic planning
            </a>
            <h3>Strategic planning</h3>
            <p>
              Through surveys, targeted focus groups, and 1:1 conversations with
              Board members, administration, faculty, parents and alumni, we
              will uncover the important themes that will anchor the school's
              agenda over the next 3-5 years. Emerging trends will be
              highlighted. While the school’s committee writes the final report,
              we will help develop the themes, action plans, time frame,
              accountability and assessment.
            </p>

            <a
              name="succession_planning"
              href="#succession_planning"
              className="invisible"
            >
              Succession Planning
            </a>
            <h3>Succession Planning</h3>
            <p>
              Whether a School Administration seeks to fill key roles or a Board
              is working on the Head’s departure, we have relevant experience
              including using interim leadership.
            </p>

            <a name="gender" href="#gender" className="invisible">
              Leadership and Gender
            </a>
            <h3>Leadership and Gender</h3>
            <p>
              Whether a leader is male or female, the implications of gender
              invariably enter the independent school or workplace. Cultivating
              women’s leadership, has been among the highest priorities for
              Sullivan Stein Leadership Development.
            </p>
          </Col>
          <Col lg={3} md={4} className="column-secondary">
            <SidePreview
              link="/bios"
              moreText="Learn more Ellen Stein and Ann Sullivan"
              title="The Founders"
            >
              <div>
                <img
                  alt="The Founders, Ann Sullivan and Ellen C. Stein"
                  className="img-fluid img-thumbnail mb-2"
                  src={founders}
                />
              </div>
            </SidePreview>

            <SidePreview
              link="/missionstatement"
              moreText="Read the full Mission Statement"
              title="Mission Statement"
            >
              <p>
                Drawing on their own experiences as Heads and Administrators in
                Independent Schools as well as over 50 years on nonprofit
                Boards, Ellen Stein and Ann Sullivan are uniquely qualified to
                consult on many facets of school leadership, women in leadership
                positions, and governance for the benefit of Independent Schools
                and other non-profit organizations.
              </p>
            </SidePreview>
          </Col>
        </Row>
      </Container>
      <SiteFooter />
    </div>
  </>
);

const mapStateToProps = state => ({
  windowWidth: state.window.width,
  windowHeight: state.window.height,
  windowSize: state.window.size
});

export default connect(mapStateToProps)(Process);
