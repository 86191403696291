import React from "react";

const LinkedinSvg = props => (
  <svg width={58} height={58} {...props} className="social-icon">
    <defs>
      <filter
        x="-38%"
        y="-38%"
        width="176.1%"
        height="176.1%"
        filterUnits="objectBoundingBox"
        id="a"
      >
        <feOffset dy={3} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation={3}
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.218149038 0"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g
      filter="url(#a)"
      transform="translate(6 3)"
      fillRule="nonzero"
      fill="none"
    >
      <circle cx={23} cy={23} r={23} fill="#007AB7" />
      <path
        d="M27.866 30.71v-5.387c0-1.288-.019-2.943-1.786-2.943-1.792 0-2.061 1.4-2.061 2.847v5.483h-3.438V19.623h3.29v1.52h.045a3.615 3.615 0 0 1 3.258-1.789c3.483 0 4.129 2.3 4.129 5.283v6.073h-3.437zm-9.456 0h-3.443V19.623h3.444V30.71h-.001zm-3.712-14.6a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default LinkedinSvg;

