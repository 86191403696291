import React from 'react';
import { connect } from 'react-redux';
import {setWindowSize } from '../actions/window';

class WindowDimensions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            height: 0
        };
        this.updateDimensions = this.updateDimensions.bind(this);
    };

    updateDimensions () {
        this.props.setWindowSize({width: window.innerWidth, height: window.innerHeight});
    }

    componentWillMount () {
        this.updateDimensions();
    }

    componentDidMount () {
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount () {
        window.removeEventListener("resize", this.updateDimensions);
    }

    render () {
        return null;
    }
}
const mapDispatchToProps = (dispatch) => ({
    setWindowSize: (dimensions) => dispatch(setWindowSize(dimensions)),
});
export default connect(undefined, mapDispatchToProps)(WindowDimensions);