import { SET_WINDOW_SIZE } from '../types/window';
const windowReducerDefaultState = {
    width: 0,
    height: 0
};

const getSizeName = (width) => {
    if ( width < 576 ) {
        return 'xs';
    } else if ( width < 768 ) {
        return 'sm';
    } else if ( width < 992 ) {
        return 'md';
    } else if ( width < 1200 ) {
        return 'lg';
    } else if ( width >= 1200 ) {
        return 'xl';
    } else {
        return '';
    }
}

export default (state=windowReducerDefaultState, action) => {
    switch (action.type) {
        case SET_WINDOW_SIZE:
            return {
                ...state,
                width: action.width,
                height: action.height,
                size: getSizeName(action.width)
            };
        default:
            return state;
    }
};
