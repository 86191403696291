import React from "react";

const FacebookSvg = props => (
  <svg width={58} height={58} {...props} className="social-icon">
    <defs>
      <filter
        x="-38%"
        y="-38%"
        width="176.1%"
        height="176.1%"
        filterUnits="objectBoundingBox"
        id="a"
      >
        <feOffset dy={3} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation={3}
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.218149038 0"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g
      filter="url(#a)"
      transform="translate(6 3)"
      fillRule="nonzero"
      fill="none"
    >
      <circle cx={23} cy={23} r={23} fill="#3D5A96" />
      <path
        d="M24.644 32.968h-4.419v-9.414h-2.209v-3.628h2.209V17.75c0-2.957 1.246-4.718 4.791-4.718h2.947v3.628h-1.847c-1.379 0-1.471.506-1.471 1.453l-.001 1.813h3.34l-.391 3.628h-2.949v9.414z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default FacebookSvg;

