import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../css/App.css';
import Home from './Home';

class App extends Component {
  render() {
    return (
      <div className={`App ${this.props.windowSize}`}>
        <Home />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  windowWidth: state.window.width,
  windowHeight: state.window.height,
  windowSize: state.window.size,
});

export default connect(mapStateToProps)(App);
