import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
// React bootstrap:
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
// Css:
import "../css/missionstatement.css";
// Components:
import SiteNav from "../Components/SiteNav";
import SiteFooter from "../Components/SiteFooter";
import SidePreview from "../Components/SidePreview";
// Assets:
import founders from "../assets/Founders.jpg";

class MissionStatement extends React.Component {
  componentDidMount() {
    document.body.classList.add("missionstatement");
  }

  componentWillUnmount() {
    document.body.classList.remove("missionstatement");
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Sullivan Stein - Mission Statement</title>
        </Helmet>
        <div
          className={`page missionstatement d-flex flex-column ${this.props.windowSize}`}
        >
          <SiteNav active="missionstatement" />
          <Container className="main">
            <Row>
              <Col
                lg={9}
                md={8}
                className="column-primary column-border-right pr-5"
              >
                <h1 className="text-center mb-5">Mission Statement</h1>
                <p className="lead">
                  Drawing on their own experiences as Heads and Administrators
                  in Independent Schools as well as over 50 combined years on
                  nonprofit Boards, Ellen Stein and Ann Sullivan are uniquely
                  qualified to consult on many facets of school leadership,
                  women in leadership positions, and governance for the benefit
                  of Independent Schools and other non-profit organizations.
                  They coach and advise Heads and Assistant Heads of Schools,
                  Directors of Enrollment, Directors of College Counseling,
                  Directors of Development, Division Heads, Department Chairs,
                  Board Chairs, and Trustee Leaders. In addition, they use their
                  own experiences with strategic planning and succession
                  planning, including interim roles in advising Boards and
                  School Administrators.
                </p>
              </Col>
              <Col lg={3} md={4} className="column-secondary">
                <SidePreview
                  link="/bios"
                  moreText="Learn more Ellen Stein and Ann Sullivan"
                  title="The Founders"
                >
                  <img
                    alt="The Founders, Ann Sullivan and Ellen C. Stein"
                    className="img-fluid img-thumbnail mb-2"
                    src={founders}
                  />
                </SidePreview>

                <SidePreview
                  link="/whatwedo"
                  moreText="More about what we do"
                  title="Executive Coaching"
                >
                  <p>
                    Our goal is to develop new strengths in leaders so they can
                    problem solve on their own. This is done through monthly or
                    more frequent conversations focusing on the areas of concern
                    initiated by the client and helping them develop greater
                    leadership capacities.
                  </p>
                </SidePreview>
              </Col>
            </Row>
          </Container>
          <SiteFooter />
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  windowWidth: state.window.width,
  windowHeight: state.window.height,
  windowSize: state.window.size
});

export default connect(mapStateToProps)(MissionStatement);
