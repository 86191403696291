import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
// React bootstrap:
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Figure from "react-bootstrap/Figure";
import Row from "react-bootstrap/Row";
// Components:
import SiteFooter from "../Components/SiteFooter";
import SiteNav from "../Components/SiteNav";
import SidePreview from "../Components/SidePreview";
// CSS:
import "../css/bios.css";
// Assets:
import founders from "../assets/FoundersHorizontal.jpg";

const Bios = props => (
  <>
    <Helmet>
      <title>Sullivan Stein - Bios</title>
    </Helmet>
    <div className={`page bios d-flex flex-column ${props.windowSize}`}>
      <SiteNav active="bios" />
      <Container className="main">
        <Row>
          <Col
            lg={9}
            md={8}
            className="column-primary column-border-right pr-5"
          >
            <h1 className="text-center mb-5">The Founders</h1>
            <Row>
              <Col className="d-flex justify-content-center">
                <Figure>
                  <Figure.Image
                    alt="The Founders, Ann Sullivan and Ellen C. Stein"
                    className="img-fluid img-thumbnail"
                    src={founders}
                  />
                  <Figure.Caption>
                    The founders, Ellen C. Stein and Ann Sullivan
                  </Figure.Caption>
                </Figure>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={12} lg={6}>
                <h2>
                  <Link to="bios/ellenstein">Ellen C. Stein</Link>
                </h2>
                <p>
                  Currently a partner in Sullivan Stein Leadership Development,
                  Ellen Stein completed 17 years as Head of The Dalton School, a
                  coeducational K-12 private school in Manhattan. For the six
                  years prior to that she was Dalton's Associate Head of School
                  and Director of the Lower School&hellip; <br />
                  <Link to="/bios/ellenstein">> More</Link>
                </p>
              </Col>
              <Col>
                <h2>
                  <Link to="bios/annsullivan">Ann Sullivan</Link>
                </h2>
                <p>
                  Following her tenure as Associate Head of Rye Country Day
                  School, Ann Sullivan co-founded Sullivan Stein Leadership
                  Development with Ellen Stein in 2018. Previously, she served
                  as the Head at School at the Holy Child in Rye, New York, an
                  independent Catholic School for Girls, for 14 years&hellip;{" "}
                  <br />
                  <Link to="/bios/annsullivan">> More</Link>
                </p>
              </Col>
            </Row>
          </Col>
          <Col lg={3} md={4} className="column-secondary">
            <SidePreview
              link="/whatwedo"
              moreText="More about what we do"
              title="Leadership Teams"
            >
              <p>
                Administrative teams, departmental teams, and grade level
                leaders need to work successfully together. We will review the
                dynamics of each team, analyse individual strengths and note how
                these can be cooperatively utilized on the Leadership Team. We
                conduct Leadership Team retreats at the outset or conclusion of
                a school year.
              </p>
            </SidePreview>

            <SidePreview
              link="/whatwedo"
              moreText="More about what we do"
              title="Organizational Review"
            >
              <p>
                We will analyse individual departments or areas (e.g.,
                admissions, development, the college office) using surveys,
                focus groups, confidential interviews, observations
                incorporating emerging trends (with data from NAIS, NYSAIS, and
                established consulting firms). After a thorough review, we will
                provide recommendations and ongoing support, including coaching
                as needed.
              </p>
            </SidePreview>
          </Col>
        </Row>
      </Container>
      <SiteFooter />
    </div>
  </>
);

const mapStateToProps = state => ({
  windowWidth: state.window.width,
  windowHeight: state.window.height,
  windowSize: state.window.size
});

export default connect(mapStateToProps)(Bios);
