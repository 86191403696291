import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// css:
import "../css/sitenav.css";
// React-bootstrap:
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

const SiteNav = props => {
  const pageName = props.active;
  return (
    <Navbar
      id="sitenav"
      className={`sitenav fixed-top ${pageName} ${props.windowSize}`}
      bg="light"
      expand="lg"
    >
      <Container>
        <Navbar.Brand as={Link} to="/">
          Sullivan Stein
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link
              as={Link}
              to="/missionstatement"
              className={pageName === "missionstatement" ? "active" : ""}
            >
              Mission Statement
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/focusareas"
              className={pageName === "process" ? "active" : ""}
            >
              Focus Areas
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/bios"
              className={pageName === "bios" ? "active" : ""}
            >
              Bios
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/contact"
              className={pageName === "contact" ? "active" : ""}
            >
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
const mapStateToProps = state => ({
  windowWidth: state.window.width,
  windowHeight: state.window.height,
  windowSize: state.window.size
});

export default connect(mapStateToProps)(SiteNav);
