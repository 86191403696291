import { createStore, combineReducers } from 'redux';
import windowReducer from '../reducers/window';
// import thunk from 'redux-thunk';

export default () => {
    const store = createStore(
        combineReducers({
            window: windowReducer
        })
    );
    return store;
}