import React from "react";
import { connect } from "react-redux";
// React bootstrap:
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
// Components:
import SiteFooter from "../../Components/SiteFooter";
import SiteNav from "../../Components/SiteNav";
import SidePreview from "../../Components/SidePreview";
// Assets:
import PortraitAnn from "../../assets/PortraitAnn.jpg";

const AnnSullivan = props => (
  <div className={`page bios d-flex flex-column ${props.windowSize}`}>
    <SiteNav active="bios" />
    <Container className="main">
      <Row>
        <Col lg={9} md={8} className="column-primary column-border-right pr-5">
          <Row>
            <Col></Col>
          </Row>
          <Row>
            <Col lg={4}>
              <img
                src={PortraitAnn}
                className="img-fluid img-thumbnail mt-2 mb-3"
                alt="Ellen C. Stein"
              />
            </Col>
            <Col>
              <h1>Ann Sullivan</h1>
              <p className="lead">
                Following her tenure as Associate Head of Rye Country Day
                School, Ann Sullivan co-founded Sullivan Stein Leadership
                Development with Ellen Stein in 2018. Previously, she served as
                the Head at School at the Holy Child in Rye, New York, an
                independent Catholic School for Girls, for 14 years as well as
                the Interim Head of Lincoln School, a Quaker School for Girls in
                Providence, Rhode Island
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                —both following her 24 year tenure at Friends Seminary where she
                served as Director of Development, Director of Admission,
                Director of College Counseling, and Chair of the English
                Department until 1999.
              </p>
              <p>
                She is currently a member of the Board of Trustees at Friends
                Seminary and has served on the boards of the Cornelia Connelly
                Middle School and The Windward School in New York City as well
                as College of the Atlantic in Maine, the New York State
                Association of Independent Schools, and the Rye Free Reading
                Room. In addition, she is a member of the Country Day School
                Heads Association, Headmistresses Association of the East
                (HMAE), and the Heads Network, and served as Co-Chair of the
                Independent School Admissions Association of Greater New York
                (ISAAGNY) and President of the Fairchester Heads Association.
                Ann was on the first faculty of the Carney Sandoe Workshop on
                Women in Leadership in Independent Schools (2017).
              </p>
              <p>
                Ann received her degree in English Literature from Good Counsel
                College and her MA in English from New York University; she was
                a faculty member of the University Schools and student in
                American Studies at the University of Iowa and a Visiting Head
                at the Klingenstein Program at Teachers College (2008). She
                completed the Columbia University Coaching Program, Front-End
                intensive and 9 months Practicum 2018-19.
              </p>
            </Col>
          </Row>
        </Col>
        <Col lg={3} md={4} className="column-secondary">
          <SidePreview
            link="/whatwedo"
            moreText="More about what we do"
            title="Executive Coaching"
          >
            <p>
              Our goal is to develop new strengths in leaders so they can
              problem solve on their own. This is done through monthly or more
              frequent conversations focusing on the areas of concern initiated
              by the client and helping them develop greater leadership
              capacities.
            </p>
          </SidePreview>
        </Col>
      </Row>
    </Container>
    <SiteFooter />
  </div>
);

const mapStateToProps = state => ({
  windowWidth: state.window.width,
  windowHeight: state.window.height,
  windowSize: state.window.size
});

export default connect(mapStateToProps)(AnnSullivan);
