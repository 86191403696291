import React, { Component } from "react";
import { connect } from "react-redux";
// React bootstrap:
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
// Components:
import FacebookSvg from "./FacebookSvg";
import LinkedinSvg from "./LinkedinSvg";
// css:
import "../css/sitefooter.css";

class SiteFooter extends Component {
  render() {
    return (
      <div className="sitefooter mt-auto pt-5">
        <footer className="py-5">
          <Container>
            <Row>
              <Col className="d-flex justify-content-center">
                <a href="https://www.facebook.com/Sullivan-Stein-635101740245654/">
                  <FacebookSvg />
                </a>
                <a href="https://www.linkedin.com/company/sullivanstein">
                  <LinkedinSvg />
                </a>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col className="text-center">
                <p className="mt-3">
                  &copy;2019 Sullivan Stein Leadership Development, LLC. All
                  rights reserved.
                </p>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  windowSize: state.window.size
});

export default connect(mapStateToProps)(SiteFooter);
