import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
// CSS:
import '../css/contact.css';
// React bootstrap:
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
// Components:
import ContactForm from '../Components/ContactForm';
import SiteNav from '../Components/SiteNav';
import SiteFooter from '../Components/SiteFooter';
import SidePreview from '../Components/SidePreview';
import founders from '../assets/Founders.jpg';

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = { validated: false };
    }
    componentDidMount() {
        document.body.classList.add('contact');
    }

    componentWillUnmount() {
        document.body.classList.remove('contact');
    }


    render() {
        return (
        <>
            <Helmet>
                <title>Sullivan Stein - Contact</title>
            </Helmet>
            <div className={`page contact d-flex flex-column ${this.props.windowSize}`}>
                <SiteNav active="contact" />
                <Container className="main">
                    <Row>
                        <Col lg={9} md={8} className="column-primary column-border-right pr-5">
                            <h1 className="text-center mb-5">Contact</h1>
                            <p className="lead">
                                Get in touch with us
                            </p>
                            <hr className="mb-4"/>
                            <ContactForm success="false"/>
                        </Col>
                        <Col lg={3} md={4} className="column-secondary">
                            <SidePreview link="/bios" moreText="Learn more Ellen Stein and Ann Sullivan" title="The Founders">
                                <img
                                    alt="The Founders, Ann Sullivan and Ellen C. Stein"
                                    className="img-fluid img-thumbnail mb-2"
                                    src={founders}
                                />
                            </SidePreview>

                            <SidePreview link="/whatwedo" moreText="Learn more about what we do" title="Strategic Planning">
                                <p>Through surveys, targeted focus groups, and 1:1 conversations with Board members, administration, faculty, parents and alumni, we will uncover the important themes that will anchor the school's agenda over the next 3-5 years. Emerging trends will be highlighted. While the school’s committee writes the final report, we will help develop the themes, action plans, time frame, accountability and assessment.</p>
                            </SidePreview>
                        </Col>
                    </Row>
                </Container>
                <SiteFooter />
            </div>
        </>
    )}
}

const mapStateToProps = (state) => ({
    windowWidth: state.window.width,
    windowHeight: state.window.height,
    windowSize: state.window.size,
});

export default connect(mapStateToProps)(Contact);
